import React from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  FormGroup,
  FormControlLabel,
  FormLabel,
  Switch,
  IconButton,
  Checkbox,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import DeleteIcon from "@mui/icons-material/Delete";
import SimCardDownloadRoundedIcon from "@mui/icons-material/SimCardDownloadRounded";
import AddBoxIcon from "@mui/icons-material/AddBox";
import UploadIcon from '@mui/icons-material/Upload';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import moment from 'moment';
import { useVendor } from "core";
import useDerivedState from '@twipped/hooks/useDerivedState';

import { IMaskInput } from "react-imask";
import PropTypes from "prop-types";
const APIROOT = process.env.API_ENDPOINT;

const SSNMaskCustom = React.forwardRef(function SSNMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="#00-00-0000"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

SSNMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const EINMaskCustom = React.forwardRef(function EINMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="#0-0000000"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

EINMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const NotNumberMaskCustom = React.forwardRef(function NotNumberMaskCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="###############"
      definitions={{
        "#": /^[a-zA-Z]+$/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

NotNumberMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function CertificationAndLegal({ data }) {
  const { states, vendorDetail, formValue, setFormValue, activeVendor, updateVendorCertificate, 
    deleteVendorLicense, updateVendorField, uploadVendorCertificate, downloadVendorCertificate, downloadVendorInsurance } = useVendor();

  const [stateOne, setStateOne] = React.useState("");
  const [checked, setChecked] = useDerivedState(formValue.active === "Y",[formValue.active]);

  const [updatedFields, setUpdatedFields] = React.useState({}); 
  const [updatedCerts, setUpdatedCerts] = React.useState([]); 


  const activeVendorChange = (event) => {
    setChecked(event.target.checked);
    if(event.target.checked){
      activeVendor(formValue.userid, "Y");
    } else{
      activeVendor(formValue.userid, "N");
    }
  };

  const activeVendorSwitch = () => {
    if(formValue.userid) {
    return(
      <FormGroup row sx={{ display: "inline-flex" }}>
        <FormControlLabel
          control={<Switch checked={checked} color="success" onChange={activeVendorChange} />}
          label="ACCOUNT ACCESS"
          name="active"
          labelPlacement="top"
        />
      </FormGroup>
      );
    }
  }

  const handleLinceseUpdated = (event, index) => {

    if(updatedCerts[index] ) {
      updateVendorCertificate(formValue.certificates)
    } 

    delete updatedCerts[index]
  };

  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  const handlePassword = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const normalizeValue = (value) => {
    return value || "";
  };

  const handleInputCertificateChange = (event, index) => {

    const { name, value } = event.target;
    const updatedCertificates = formValue.certificates;
    formValue.certificates[index][name] = value;
    setFormValue({
      ...formValue,
      certificates: updatedCertificates,
    });

      updatedCerts[index] = updatedCertificates;
  };

  const handleTaxTypeChange = (event) => {

    const { name, value } = event.target;

    let reset = (value === 'ein') ? 'tax_ein' : 'tax_ssn' 

    setFormValue({
      ...formValue,
      [reset] : ''
    });

    updateVendorField({"vendorid": formValue?.userid, "fieldname": reset, "fieldvalue": '' });

    handleInputChange(event)
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValue({
      ...formValue,
      [name]: value,
    });

    // To know what fields have been updated
    setUpdatedFields({
      ...updatedFields,
      [name]: value
    })
  };

  const updateField = (event) => {

    const { name, value } = event.target;

    if(updatedFields[name]){

      updateVendorField({"vendorid": formValue?.userid || vendorDetail.userid, "fieldname": name, "fieldvalue": value });

      if(['firstname','lastname','phone', 'vendor_type'].includes(name)) {
        
        syncAvailableVendors(name, value)
      }

      delete updatedFields[name]
    }

  };

  const addLicensure = () => {
    return formValue?.certificates?.map((item, index) => {
      return (
        <Grid
          container
          spacing={1}
          rowSpacing={2}
          key={index}
          sx={{
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px #f2f2f2 solid",
          }}
        >
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormControl fullWidth>
              <InputLabel id="license_type-label">LICENSE TYPE</InputLabel>
              <Select
                labelId="license_type-label"
                id="license_type"
                label="LICENSE TYPE"
                name="license_type"
                value={item?.license_type ? normalizeValue(item?.license_type) : "CS"}
                onChange={(e) => handleInputCertificateChange(e, index)}
                onBlur={(e) => handleLinceseUpdated(e, index)}
                required
              >
                <MenuItem value=""></MenuItem>
                <MenuItem value="CS">Certificate of Standing</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormControl fullWidth>
              <InputLabel id="license_state-label">STATE</InputLabel>
              <Select
                labelId="license_state-label"
                id="license_state"
                name="license_state"
                value={normalizeValue(item?.license_state)}
                label="STATE"
                onChange={(e) => handleInputCertificateChange(e, index)}
                onBlur={(e) => handleLinceseUpdated(e, index)}
                required
              >
                {states.map((state) => (
                  <MenuItem key={state} value={state}>
                    {state}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <TextField
              fullWidth
              id="license_number"
              name="license_number"
              value={normalizeValue(item?.license_number)}
              label="LICENSE NUMBER"
              variant="outlined"
              onChange={(e) => handleInputCertificateChange(e, index)}
              onBlur={(e) => handleLinceseUpdated(e, index)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <TextField
              fullWidth
              id="license_obtained_date"
              label="DATE OBTAINED"
              name="license_obtained"
              type="date"
              defaultValue={moment(item.license_obtained).format('YYYY-MM-DD')}
              onChange={(e) => handleInputCertificateChange(e, index)}
              onBlur={(e) => handleLinceseUpdated(e, index)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <TextField
              fullWidth
              id="license_expiration_date"
              label="EXPIRATION DATE"
              name="license_expiration"
              type="date"
              defaultValue={moment(item.license_expiration).format('YYYY-MM-DD')}
              onChange={(e) => handleInputCertificateChange(e, index)}
              onBlur={(e) => handleLinceseUpdated(e, index)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormControl sx={{ width: "53%" }}>
              <InputLabel id="license_obtained-label">STATUS</InputLabel>
              <Select
                labelId="license_status-label"
                id="status"
                name="license_status"
                value={normalizeValue(item?.license_status)}
                label="STATUS"
                onChange={(e) => handleInputCertificateChange(e, index)}
                onBlur={(e) => handleLinceseUpdated(e, index)}
                required
              >
                <MenuItem value="Y">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
                <MenuItem value="Not Eligible">Not Eligible</MenuItem>
                <MenuItem value="Eligible">Eligible</MenuItem>
                <MenuItem value="Deceased">Deceased</MenuItem>
              </Select>
            </FormControl>
            <IconButton
              aria-label="delete"
              color="error"
              size="large"
              sx={{ float: "right" }}
              onClick={() => {

                let certificate = formValue?.certificates[index]

                deleteVendorLicense({
                  userid: certificate.userid,
                  uid: certificate.uid
                })

                const allCertificates = [...formValue?.certificates];
                allCertificates.splice(index, 1);
                setFormValue({
                  ...formValue,
                  certificates: allCertificates,
                });
              }}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              aria-label="download"
              color="primary"
              size="large"
              sx={{ float: "right" }}
              onClick={()=>{
                handleCertDownload(`${vendorDetail?.userid}_${item?.license_state}.pdf`)
              }}
            >
              <SimCardDownloadRoundedIcon />
            </IconButton>
            <label htmlFor="license-file">
              <input 
                accept="application/pdf"
                id="license-file"
                type="file"
                style={{ display: 'none' }}
                onChange={(e) => handleCertUpload(e, item?.license_state)}/>
              <IconButton
                aria-label="upload"
                color="primary"
                size="large"
                component="span"
                sx={{ float: "right" }}
              >
                <UploadIcon />
              </IconButton>
            </label>
          </Grid>
        </Grid>
      );
    });
  };

  const handleCertUpload = async(e, state) => {
    if (!e.target.files) {
      return;
    }
    if (!state) {
      return
    }
    await uploadVendorCertificate(e.target.files[0], "license", state)
  }

  const handleCertDownload = async(filename) => {
    downloadVendorCertificate(filename).then(blob => {
      // Creating new object of PDF file
      const fileURL = window.URL.createObjectURL(new Blob([blob], {type: 'application/pdf'}));
      let alink = document.createElement('a');
      alink.href = fileURL;
      alink.download = filename;
      alink.click();
    })
  }

  const handleInsuranceUpload = async(e) => {
    if (!e.target.files) {
      return;
    }
    await uploadVendorCertificate(e.target.files[0], "insurance")
  }

  const handleInsuranceDownload = async(filename) => {
    downloadVendorInsurance(filename).then(blob => {
      // Creating new object of PDF file
      const fileURL = window.URL.createObjectURL(new Blob([blob]));
      let alink = document.createElement('a');
      alink.href = fileURL;
      alink.download = filename;
      alink.click();
    })
  }

  const addInsurance = () => {
      return (
        <Grid
          container
          spacing={1}
          rowSpacing={2}
          sx={{
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px #f2f2f2 solid",
          }}
        >
          <Grid item xs={12} sm={6} md={6} lg={2}>
            <FormControl fullWidth>
              <InputLabel id="insurance_type-select-label">POLICY TYPE</InputLabel>
              <Select
                labelId="insurance_type-select-label"
                id="insurance_type"
                name="insurance_type"
                value={formValue?.insurance_type ? formValue?.insurance_type : "E&O"}
                onChange={(event)=>handleInputChange(event)}
                onBlur={(event)=>updateField(event)}
                label="POLICY TYPE"
                required
              >
                <MenuItem value=""></MenuItem>
                <MenuItem value="E&O">E&O</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2}>
            <TextField 
              fullWidth 
              id="PROVIDER" 
              name="insurance_company" 
              value={formValue?.insurance_company}
              onChange={(event)=>handleInputChange(event)}
              onBlur={(event)=>updateField(event)}
              label="PROVIDER" 
              required 
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2}>
            <TextField
              fullWidth
              id="date1"
              label="EXPIRATION DATE"
              name="insurance_expires"
              type="date"
              defaultValue={moment(formValue?.insurance_expires).format('YYYY-MM-DD')}
              onChange={(event)=>handleInputChange(event)}
              onBlur={(event)=>updateField(event)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2}>
            <TextField
              fullWidth
              id="date"
              label="AMOUNT (EACH)"
              name="insurance_amount_each"
              defaultValue={formValue?.insurance_amount_each}
              onChange={(event)=>handleInputChange(event)}
              onBlur={(event)=>updateField(event)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <TextField
              sx={{ width: "50%" }}
              id="AGGREGATE"
              label="AGGREGATE AMOUNT"
              name="insurance_amount_agg"
              value={normalizeValue(formValue?.insurance_amount_agg)}
              onChange={(event)=>handleInputChange(event)}
              onBlur={(event)=>updateField(event)}
              required
            />
            <IconButton
              aria-label="delete"
              color="error"
              size="large"
              sx={{ float: "right" }}
              onClick={() => {
                const allInsurance = [...formValue?.insurance];
                allInsurance.splice(0, 1);
                setFormValue({
                  ...formValue,
                  insurance: allInsurance,
                });
              }}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              aria-label="download"
              color="primary"
              size="large"
              sx={{ float: "right" }}
              onClick={()=>{
                handleInsuranceDownload(`${vendorDetail?.userid}_eo_insurance.pdf`)
              }}
            >
              <SimCardDownloadRoundedIcon />
            </IconButton>
            <label htmlFor="license-file">
              <input 
                accept="application/pdf"
                id="license-file"
                type="file"
                style={{ display: 'none' }}
                onChange={handleInsuranceUpload}/>
              <IconButton
                aria-label="upload"
                color="primary"
                size="large"
                component="span"
                sx={{ float: "right" }}
              >
                <UploadIcon />
              </IconButton>
            </label>
          </Grid>
        </Grid>
      );
  };

  return (
    <>
      <Typography variant="h4" component="h4">
        Licensure
      </Typography>
      {addLicensure()}
      <Grid container spacing={1} rowSpacing={2}>
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <Button
            variant="text"
            endIcon={<AddBoxIcon />}
            size="large"
            color="primary"
            className="rightM"
            onClick={() => {
              updateVendorCertificate([{
                userid: formValue.userid,
                uid: (new Date()).getTime(),
                new: true
              }])
            }}
          >
            Add LICENSE
          </Button>
        </Grid>
      </Grid>
      <Typography variant="h4" component="h4">
        Insurance
      </Typography>
      {addInsurance()}
      <Grid container spacing={1} rowSpacing={2}>
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <Button
            variant="text"
            endIcon={<AddBoxIcon />}
            size="large"
            color="primary"
            className="rightM"
            onClick={() => {
              setFormValue({
                ...formValue,
                insurance: [...formValue?.insurance, {}],
              });
            }}
          >
            Add POLICY
          </Button>
        </Grid>
      </Grid>

      <Typography variant="h4" component="h4">
        Payment & Tax Information
      </Typography>

      <Grid container spacing={1} rowSpacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <TextField
            fullWidth
            id="w9-fullName"
            name="tax_fullname"
            value={normalizeValue(formValue.parent_user > 0 ? formValue.parent_tax_fullname : formValue?.tax_fullname)}
            disabled={formValue.parent_user > 0}
            label="W-9 FULL NAME"
            variant="outlined"
            onChange={(event)=>handleInputChange(event)}
            onBlur={(event)=>updateField(event)}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <TextField
            fullWidth
            id="w9-address"
            name="tax_address"
            value={normalizeValue(formValue.parent_user > 0 ? formValue.parent_tax_address : formValue?.tax_address)}
            disabled={formValue.parent_user > 0}
            label="W-9 ADDRESS"
            variant="outlined"
            onChange={(event)=>handleInputChange(event)}
            onBlur={(event)=>updateField(event)}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <TextField
            fullWidth
            id="w9-city"
            name="tax_city"
            value={normalizeValue(formValue.parent_user > 0 ? formValue.parent_tax_city : formValue?.tax_city)}
            disabled={formValue.parent_user > 0}
            label="W-9 CITY"
            variant="outlined"
            onChange={(event)=>handleInputChange(event)}
            onBlur={(event)=>updateField(event)}
            required
            InputProps={{
              inputComponent: NotNumberMaskCustom,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <FormControl fullWidth>
            <InputLabel id="tax_state-label">W-9 STATE</InputLabel>
            <Select
              labelId="tax_state-label"
              id="tax_state"
              name="tax_state"
              value={formValue.parent_user > 0 ? formValue.parent_tax_state : formValue?.tax_state}
              disabled={formValue.parent_user > 0}
              label="W-9 STATE"
              onChange={(event)=>handleInputChange(event)}
              onBlur={(event)=>updateField(event)}
              required
            >
              {states.map((state) => (
                <MenuItem key={state} value={state}>
                  {state}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={3}>
          <TextField
            fullWidth
            id="w9-tax_company"
            name="tax_company"
            value={normalizeValue(formValue.parent_user > 0 ? formValue.parent_tax_company : formValue?.tax_company)}
            disabled={formValue.parent_user > 0}
            label="W-9 COMPANY NAME"
            variant="outlined"
            onChange={(event)=>handleInputChange(event)}
            onBlur={(event)=>updateField(event)}
            required
          />
        </Grid>
        {/* <Grid item xs={12} sm={6} md={6} lg={3}>
          <TextField
            fullWidth
            id="date2"
            label="EFFECTIVE DATE"
            name="tax_effective_date"
            type="date"
            value={
              taxEffectiveDate || normalizeValue(formValue?.tax_effective_date)
            }
            onChange={(event)=>handleInputChange(event)}
            onBlur={(event)=>updateField(event)}
            required
          />
        </Grid> */}
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={3}
          style={{ textAlign: "center", paddingTop: "24px" }}
        >
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="tax_type"
              name="tax_type"
              defaultValue={
                formValue.parent_user > 0 ? 
                  formValue?.parent_tax_entity_type === '' || formValue?.parent_tax_entity_type !== "IND" ? "ssn" : "ein"
                : 
                  formValue?.tax_entity_type === '' || formValue?.tax_entity_type !== "IND" ? "ssn" : "ein"
              }
              onChange={(event)=>handleTaxTypeChange(event)}
              required
            >
              <FormControlLabel value="ssn" control={<Radio />} label="SSN" disabled={formValue.parent_user > 0}/>
              <FormControlLabel value="ein" control={<Radio />} label="EIN" disabled={formValue.parent_user > 0}/>
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>
     
          { !formValue?.tax_type || formValue?.tax_entity_type === "IND" || formValue?.tax_type === 'ssn' ?
        
            <TextField
              fullWidth
              id="w9-tax_ssn"
              name="tax_ssn"
              value={normalizeValue(formValue.parent_user > 0 ? formValue.parent_tax_ssn : formValue?.tax_ssn)}
              disabled={formValue.parent_user > 0}
              label="SSN"
              variant="outlined"
              onChange={(event)=>handleInputChange(event)}
              onBlur={(event)=>updateField(event)}
              required
              InputProps={{
                inputComponent: SSNMaskCustom,
              }}
            />
            :

            <TextField
              fullWidth
              id="w9-tax_ein"
              name="tax_ein"
              value={normalizeValue(formValue.parent_user > 0 ? formValue.parent_tax_ein : formValue?.tax_ein)}
              disabled={formValue.parent_user > 0}
              label="EIN"
              variant="outlined"
              onChange={(event)=>handleInputChange(event)}
              onBlur={(event)=>updateField(event)}
              required
              InputProps={{
                inputComponent: EINMaskCustom,
              }}
            />
           
        }

        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={3}>
            <FormControl fullWidth>
              <InputLabel id="license_state-label">W9 Entity</InputLabel>
              <Select
                labelId="w9-tax-entity-label"
                id="w9-tax_entity_type"
                name="tax_entity_type"
                value={normalizeValue(formValue.parent_user > 0 ? formValue.parent_tax_entity_type : formValue?.tax_entity_type)}
                disabled={formValue.parent_user > 0}
                label="W-9 ENTITY"
                onChange={(event)=>handleInputChange(event)}
                onBlur={(event)=>updateField(event)}
                required
              > 
                  <MenuItem value=""></MenuItem>
                  <MenuItem value="IND">Individual</MenuItem>
                  <MenuItem value="CORP">Corporation</MenuItem>
                  <MenuItem value="SCORP">S Corporation</MenuItem>
                  <MenuItem value="PARTNER">Partnership</MenuItem>
              </Select>
            </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3} style={{ textAlign: "center"}}>
          <FormControl  style={{ textAlign: "center"}} fullWidth>
            <FormLabel id="tax_subject_tobackup_withholding_label">SUBJECT TO BACKUP WITHOLDNG</FormLabel>
            <RadioGroup
              style={{ textAlign: "center"}}
              row
              aria-labelledby="tax_subject_tobackup_withholding_label"
              name="tax_subject_tobackup_withholding"
              defaultValue={normalizeValue(formValue.parent_user > 0 ? formValue.parent_tax_subject_tobackup_withholding : formValue?.tax_subject_tobackup_withholding)}
              onChange={(event)=>handleInputChange(event)}
              onBlur={(event)=>updateField(event)}
              required
              style={{justifyContent: 'center'}}
            >
              <FormControlLabel value="Y" control={<Radio />} label="Yes" disabled={formValue.parent_user > 0}/>
              <FormControlLabel value="N" control={<Radio />} label="No" disabled={formValue.parent_user > 0}/>
            </RadioGroup>
          </FormControl>

        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={3} style={{ textAlign: "center"}}>
          <FormControl  style={{ textAlign: "center"}} fullWidth>
            <FormLabel id="tax_exempt_backup_withholding_label-label">EXEMPTIONS</FormLabel>
            <RadioGroup
              row
              aria-labelledby="tax_exempt_backup_withholding_label"
              name="tax_exempt_backup_withholding"
              defaultValue={normalizeValue(formValue.parent_user > 0 ? formValue.parent_tax_subject_tobackup_withholding : formValue?.tax_subject_tobackup_withholding)}
              onChange={(event)=>handleInputChange(event)}
              onBlur={(event)=>updateField(event)}
              required
              style={{justifyContent: 'center'}}
            >
              <FormControlLabel value="Y" control={<Radio />} label="Yes" disabled={formValue.parent_user > 0}/>
              <FormControlLabel value="N" control={<Radio />} label="No" disabled={formValue.parent_user > 0}/>
            </RadioGroup>
          </FormControl>


        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <TextField
            label="FATCHA REPORTING"
            name="tax_exempt_fatcacode"
            value={normalizeValue(formValue.parent_user > 0 ? formValue.parent_tax_exempt_fatcacode : formValue?.tax_exempt_fatcacode)}
            disabled={formValue.parent_user > 0}
            onChange={(event)=>handleInputChange(event)}
            onBlur={(event)=>updateField(event)}
            fullWidth
            variant="outlined"
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <FormControl fullWidth>
            <InputLabel id="vendor_payment_preference-select-label">
              PAYMENT PREFERENCES
            </InputLabel>
            <Select
              id="vendor_payment_preference"
              name="vendor_payment_preference"
              value={normalizeValue(formValue.parent_user > 0 ? formValue.parent_vendor_payment_preference : formValue?.vendor_payment_preference)}
              disabled={formValue.parent_user > 0}
              label="PAYMENT PREFERENCES"
              onChange={(event)=>handleInputChange(event)}
              onBlur={(event)=>updateField(event)}
              required
            >
              <MenuItem value="CHECK">Pay by check</MenuItem>
              <MenuItem value="ACH">Direct Deposit (via ACH)</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={9}
          style={{ textAlign: "right" }}
        >
          <Button
            variant="text"
            endIcon={<HistoryIcon />}
            size="large"
            className="rightH"
          >
            W-9 HISTORY
          </Button>
          <Button
            variant="text"
            endIcon={<SimCardDownloadRoundedIcon />}
            size="large"
            className="rightM"
          >
            DOWNLOAD W-9
          </Button>
        </Grid> */}
      </Grid>


      <Typography variant="h5" component="h5">
        Security
      </Typography>

      <Grid container spacing={3} rowSpacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              PASSWORD
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={values.showPassword ? "text" : "password"}
              value={values.password}
              onChange={handlePassword("password")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="PASSWORD"
            />
          </FormControl>

          <FormGroup>
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="FORCE NEW PASSWORD ON NEXT LOGIN"
            />
          </FormGroup>
        </Grid>
        {/* <Grid item xs={12} sm={6} md={6} lg={4}>
          <Button variant="contained" size="large" fullWidth>
            SEND PASSWORD RESET
          </Button>
        </Grid> */}
        <Grid item xs={12} sm={12} md={12} lg={3} sx={{ textAlign: "center" }}>
          {activeVendorSwitch()}
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={2}>
          <Typography
            variant="h6"
            component="h6"
            sx={{ textAlign: "left !important" }}
          >
            DATE REGISTERED
          </Typography>
          <Typography variant="span" component="span">
            {normalizeValue(formValue?.register_date)}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={2}>
          <Typography
            variant="h6"
            component="h6"
            sx={{ textAlign: "left !important" }}
          >
            LAST RECORDED IP
          </Typography>
          <Typography variant="span" component="span">
            {normalizeValue(formValue?.last_ip)}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={2}>
          <Typography
            variant="h6"
            component="h6"
            sx={{ textAlign: "left !important" }}
          >
            LAST LOGIN DATE/TIME
          </Typography>
          <Typography variant="span" component="span">
            {normalizeValue(formValue?.last_dts)}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Typography
            variant="h6"
            component="h6"
            sx={{ textAlign: "left !important" }}
          >
            LAST RECORDED USER AGENT
          </Typography>
          <Typography variant="span" component="span">
            {normalizeValue(formValue?.last_useragent)}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
