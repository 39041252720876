import * as React from 'react';
import PropTypes from 'prop-types';
import {
  styled,
  Grid,
  Button,
  TextField,
  FormGroup,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  FormControlLabel,
  Switch } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddBoxIcon from "@mui/icons-material/AddBox";
import Dialog from '@mui/material/Dialog';
import {css} from "@mui/system";
import InputAdornment from '@mui/material/InputAdornment';

import { useVendor } from "core";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  }
}, css`
 .employees-dialog-header{
        background: ${theme.palette.primary.main};
        position: relative;
        color: #fff;
        padding: 20px 40px;
        font-weight: 400;
        font-size: 20px;
        .MuiButtonBase-root{
          color: #ffffff5e;
          top: 15px;
        }
    }

    .MuiInputLabel-formControl {
      background-color: #ffffff;
    }
    .MuiButtonBase-root{
      top: 0px;
    }
    .rightM {
      margin-right: 6px;
      color: #005fd4;
      padding: 8px 11px;
    }
`));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function FeeDialogs() {
  const { formValue, addFeeSchedules, orderType, feeOpen, setFeeModalOpen, updatreFeeSchedules } = useVendor();
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const [addFee, setAddFee] = React.useState();

  React.useEffect(() => {
    if (feeOpen.item) {
      const payload = {...feeOpen.item};
      const order = orderType.find((el) => el.descrip === payload.orderTypeId );
      const orderTwo = orderType.find((el) => el.descrip === payload.orderType );
      setAddFee({ ...feeOpen.item, orderTypeId: order.id, orderType: orderTwo.id });
    }
  }, [feeOpen]);



  const handleClickOpen = () => {
    setAddFee('');
    setFeeModalOpen({open:true, title:'Add'});
  };

  const handleClose = () => {
    setFeeModalOpen({open:false});
  };

  const normalizeValue = (value) => {
    return value || "";
  };

   const handleInputFiledChange = (event, index) => {
     const { name, value } = event.target;
      setAddFee({
        ...addFee,
        [name]: value,
        userid: formValue?.userid
      });
  };

  const handleSwitchChange = (event) => {
    const { name, checked } = event.target;
    setAddFee({
      ...addFee,
      [name]:  checked ? true : false,
      userid: formValue?.userid
    });
  };

  const saveFeeFun = () => {
    if(feeOpen.title === 'Add') {
      addFeeSchedules(addFee);
    } else {
      updatreFeeSchedules(addFee);
    }
    setFeeModalOpen({open:false})
    setAddFee('');
  }

  return (
    <div>
      <Button
        variant="text"
        endIcon={<AddBoxIcon />}
        size="large"
        color="primary"
        className="rightM"
        onClick={handleClickOpen}
        sx={{ float: "right" }}
      >
        Add Fee
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="employees-dialog-header"
        maxWidth={maxWidth}
        open={feeOpen.open}
      >
        <BootstrapDialogTitle className="employees-dialog-header" onClose={handleClose}>
          {feeOpen.title} Fee Schedule
        </BootstrapDialogTitle>
        <DialogContent dividers>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container spacing={2} rowSpacing={2}>

                <Grid item xs={12} sm={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="insurance_type-select-label">ORDER TYPE</InputLabel>
                    <Select
                      labelId="insurance_type-select-label"
                      id="orderTypeId"
                      name="orderTypeId"
                      value={addFee?.orderTypeId || undefined}
                      onChange={(event)=>handleInputFiledChange(event)}
                      label="ORDER TYPE"
                      required
                    >
                      {orderType?.map((order) => (
                        <MenuItem key={order?.id} value={order?.id}>
                          {order?.descrip}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    label="FEE"
                    name="fee"
                    value={normalizeValue(addFee?.fee)}
                    fullWidth
                    variant="outlined"
                    onChange={handleInputFiledChange}
                    type="number"
                    required
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>

                  <TextField
                    id="date"
                    label="TURN TIME (Days)"
                    name="turnaround_time"
                    fullWidth
                    variant="outlined"
                    value={normalizeValue(addFee?.turnaround_time)}
                    onChange={handleInputFiledChange}
                    type="number"
                  />

                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <FormGroup row>
                    <FormControlLabel
                        control={<Switch
                        checked={addFee?.approved || false}
                        color="success" />}
                      label="APPROVED"
                      name="approved"
                      labelPlacement="top"
                      onChange={(event)=>handleSwitchChange(event)}
                    />
                  </FormGroup>
                </Grid>

              </Grid>
            </Grid>
          </Grid>

          <Button variant="outlined" autoFocus onClick={() => setFeeModalOpen({open: false})} style={{ margin: "10px 0" }}>
            Cancel
          </Button>
          <Button variant="contained" autoFocus onClick={() => saveFeeFun()} style={{ margin: "10px 10px" }}>
            Submit
          </Button>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
