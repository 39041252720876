import React from "react";
import {
  Grid,
  styled,
  Tabs,
  Tab,
  Box,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { css } from "@mui/system";
import { useSnackbar } from 'notistack';
import ProfileTab from "./vendor/profileTab";
import CertificationAndLegal from "./vendor/certificationsAndLegal";
import OrderHistory from "./vendor/orderHistory";
import Employees from "./vendor/employees";
import Experience from "./vendor/experience";
import StateDBA from "./vendor/stateDBA";
import SidePanel from "./vendor/sidePanel";
import { useVendor } from "core";
import useDerivedState from '@twipped/hooks/useDerivedState';

const VendorContainer = styled("div")(
  ({ theme }) => css`
    .MuiTypography-h5 {
      margin: 25px 0 15px 0;
      font-size: 16px;
      font-weight: 600;
    }
    .MuiTypography-h4 {
      margin: 0 0 15px 0;
      font-size: 16px;
      font-weight: 600;
    }
    .MuiTypography-h6 {
      font-size: 12px;
      font-weight: 600;
      color: ${theme.palette.primary.main};
      text-align: center;
      .boldSpan {
        font-size: 28px;
        color: #10122d;
      }
    }
    .MuiFormControlLabel-label {
      font-size: 14px;
    }
    .MuiInputLabel-formControl {
      font-size: 0.8rem;
      line-height: 2em;
    }
    .MuiInputLabel-shrink{
      font-size: 1rem;
      line-height: 1.4375em;
    }
    .MuiTypography-p {
      margin: 30px 0 10px 0;
      display: block;
    }
    .MuiTypography-span {
      color: #98989b;
      font-size: 14px;
    }
    .headerTabs {
      background: ${theme.palette.primary.main};
      border-bottom: none;
      padding: 20px 0 0 0;
      .Mui-selected,
      .MuiFormControlLabel-label {
        color: #fff !important;
      }
      .MuiTabs-indicator {
        background: #fff;
      }
      .MuiTab-textColorPrimary {
        color: #a3bbea;
        padding: 12px 30px;
      }
    }
    .MuiInputLabel-sizeSmall {

      line-height: 24px;
    }
    .sideCardList {
      margin-bottom: 10px;
      border-left: 6px ${theme.palette.success.main} solid;
      border-radius: 0;
      position: relative;
      // height: 72vh;
      // overflow: scroll;

      &:hover{
        background: #eeeffa;
      }

      &.selected{
        background: #eeeffa;
        box-shadow: 0px 2px 10px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
      }

      &.exclude{
        border-left: 6px ${theme.palette.error.main} solid;
      }

      &:hover{
        background: #eeeffa;
      }

      &.selected{
        background: #eeeffa;
        box-shadow: 0px 2px 10px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
      }

      &.exclude{
        border-left: 6px ${theme.palette.error.main} solid;
      }

      .cardBold {
        color: ${theme.palette.primary.main};
        font-weight: 600;
        font-size: 12px;
      }
      .cardTtitleNumber {
        span {
          float: right;
        }
      }
      .MuiCardContent-root {
        padding-bottom: 16px;
      }
    }
    .sideCardList:hover {
      cursor:pointer;
    }
    .alertProfile {
      position: relative;
      margin-bottom: 15px;
      .MuiAlert-message {
        width: 100%;
      }
      .alertBtn {
        float: right;
        position: absolute;
        top: 18px;
        right: 18px;
        padding: 15px 20px;
      }
      .alertC{
       width: calc(100% - 140px);
       padding: 0;
       margin: 0;
      }
      .alertP{
        width: calc(100% - 240px);
        padding: 0;
        margin: 0;
       }
      .employ_switch {
        position: absolute;
        top: 30px;
        right: 20px;
      }
    }
    .employ_switch {
      float: right;
    }
    .personIcon{
      .MuiButton-startIcon{
        margin: 0 !important;
      }
      .MuiLoadingButton-loadingIndicator{
        left: auto !important;
      }
    }
    .employ_btn {
      width: 150px;
    }
    .image_container {
      position: relative;
      margin-top: 10px;
      .MuiInputLabel-root {
        font-size: 0.8rem;
        line-height: 1.4375em;
        color: ${theme.palette.primary.main};
      }
      .img_box {
        border: 1px rgba(0, 0, 0, 0.23) solid;
        border-radius: 4px;
        margin-top: 10px;
        img{
          display: block;
          width: 100%;
        }
      }
      Button {
        position: absolute;
        right: -10px;
        top: 15px;
      }
    }
    .height_for_sidbar{
      height: 83vh;
      overflow: scroll;
      padding-left: 24px !important;
      padding-right: 12px !important;
      padding-top: 0 !important;
    }
    .height_for_panel{
      padding-left: 12px !important;
      padding-top: 0 !important;
    }
    .padding_for_top{
      padding-bottom: 24px !important;
    }
    .TabPanelConatiner {
      background: #fff;
      height: 72vh;
      overflow: scroll;

      &.disable{
        position: relative;
        overflow: hidden !important;
        &:after{
            content: '';
            position: absolute;
            width: 100%;
            background: #fff;
            opacity: 0.6;
            height: 100%;
            top: 0;
            z-index: 1;
        }
      }
      .MuiInputLabel-formControl {
        background-color: #ffffff;
      }
    }
    .MuiTableContainer-root {
      box-shadow: none;
      .MuiTableCell-head {
        color: ${theme.palette.primary.main};
        font-weight: 600;
      }
      .MuiTableCell-body {
        padding: 8px 16px;
      }
    }
    .rightM {
      margin-right: 6px;
      color: ${theme.palette.primary.main};
      padding: 8px 11px;
    }
    .rightH{
      color: #827e7e;
      margin-right: 6px;
      padding: 8px 11px;
    }
    }
    .MuiIconButton-sizeLarge{
      padding: 15px;
    }
    .uploadBtn{
      position: absolute !important;
      bottom: 10%;
      right: 15%;
      min-width: auto !important;
      padding: 16px !important;
      border-radius: 50px !important;
      .MuiButton-startIcon{
        margin:0 !important;
      }
    }
    .removeImage{
      position: absolute !important;
      top: 19%;
      right: 25%;
      min-width: auto !important;
      padding: 6px !important;
      border-radius: 50px !important;
      .MuiButton-startIcon{
        margin:0 !important;
        svg{
          font-size: 14px;
        }
      }
    }
    .small-rows{
      th, td{
        padding: 0 16px !important;
      }
    }
  `
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


export default function VendorContactsSubPage() {
  const { availabeVendors, formValue, addVendor, tabValue, setTabValue, activeVendor , enableVendor  } = useVendor();

  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
  }, [availabeVendors]);

  const tabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const addEvendorSuccessMsg = () => enqueueSnackbar("A new vendor account has been created.", "info");

  const addVendorHandler = (e) => {
    e.preventDefault();
    if (!formValue?.tax_fullname) {
      setTabValue(1);
    } else {
      addVendor('add', {...formValue});
      addEvendorSuccessMsg();
    }
  };

  // Exclude logic is reverse logic
  // so active vendors are exclude="N"
  const [checked, setChecked] = useDerivedState(formValue?.exclude === 'N', [formValue?.exclude]);

  const enableVendorEvent = (event) => {
    if(event.target.checked){
      enableVendor(formValue.userid, "N");
      setChecked(true);
    } else{
      enableVendor(formValue.userid, "Y");
      setChecked(false);
    }
  };

  const enableVendorSwitch = () => {
    if(formValue.userid) {
      return(
        <FormGroup sx={{ margin: "0 25px" }}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Box sx={{color: 'white', fontSize: 20, padding: '4px 24px'}}>{formValue?.firstname} {formValue?.lastname}</Box>
            </Grid>
            <Grid item xs={6} sx={{textAlign: 'right'}}>
              <FormControlLabel
                control={<Switch color="success" checked={checked} onChange={enableVendorEvent} />}
                label="ENABLE VENDOR"
                labelPlacement="start"
              />
            </Grid>
          </Grid>
        </FormGroup>
        );
    }
  }

  let tabClass = 'TabPanelConatiner';
  if (!formValue.userid) {
    tabClass += ' disable';
  }

  return (
    <VendorContainer>
      <form onSubmit={addVendorHandler}>
      <Grid container spacing={3}>
        <SidePanel />
        <Grid item xs={12} sm={12} md={8} lg={9} className="height_for_panel">
          <Box
            sx={{ borderBottom: 1, borderColor: "divider" }}
            className="headerTabs"
          >
            {enableVendorSwitch()}
            <Tabs
              value={tabValue}
              onChange={tabChange}
              aria-label="basic tabs example"
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
            >
              <Tab label="PROFILE" {...a11yProps(0)} />
              <Tab label="CERTIFICATIONS & LEGAL" disabled={!formValue.userid} {...a11yProps(1)} />
              {/* <Tab label="ORDER HISTORY" disabled={!formValue.userid} {...a11yProps(2)} /> */}
              <Tab label="EMPLOYEES" disabled={!formValue.userid} {...a11yProps(3)} />
              <Tab label="EXPERIENCE" disabled={!formValue.userid} {...a11yProps(4)} />
              <Tab label="DBA Management" disabled={!formValue.userid} {...a11yProps(5)} />
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0} className={tabClass}>
            <ProfileTab />
          </TabPanel>
          <TabPanel value={tabValue} index={1} className={tabClass}>
            <CertificationAndLegal />
          </TabPanel>
          {/* <TabPanel value={tabValue} index={2} className={tabClass}>
            <OrderHistory />
          </TabPanel> */}
          <TabPanel value={tabValue} index={2} className={tabClass}>
           <Employees />
          </TabPanel>
          <TabPanel value={tabValue} index={3} className={tabClass}>
           <Experience />
          </TabPanel>
          <TabPanel value={tabValue} index={4} className={tabClass}>
           <StateDBA />
          </TabPanel>
        </Grid>
      </Grid>
      </form>
    </VendorContainer>
  );
}
