import React from "react";

import { Button, InputLabel, IconButton, styled } from "@mui/material";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

const Input = styled("input")({
  display: "none",
});

export default function UploadImage({title, userid, imageUrl, btnTitle, btnid, remove, upload}) {

  return (
    <div className="image_container">
      <InputLabel>{title}</InputLabel>
      {imageUrl ? <IconButton aria-label="delete" size="small" color="error" onClick={() => {remove(userid)}}>
        <CancelRoundedIcon fontSize="small" />
      </IconButton> : ''}
      <div className="img_box">
        {imageUrl ? <img
          src={`data:image/jpeg;base64,${imageUrl}`}
          srcSet={`data:image/jpeg;base64,${imageUrl}`}
          alt="test"
          loading="lazy"
          width="60%"
        /> : null}

        {!imageUrl ? <label htmlFor={btnid}>
          <Input
            accept="image/*"
            id={btnid}
            multiple
            type="file"
            onChange={upload}
          />
          <Button
            variant="outlined"
            component="span"
            fullWidth
            style={{ padding: "20px", border: "0" }}
          >
            {btnTitle}
          </Button>
        </label> : null}

      </div>
    </div>
  );
}
