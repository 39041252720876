import * as React from "react";
import { Grid, Box, IconButton, Checkbox, Button } from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import AddBoxIcon from "@mui/icons-material/AddBox";
import EditIcon from '@mui/icons-material/Edit';

import FeeDialogs from "./addFeeModal";


import { useVendor } from "core";

export default function VendorFee() {
  const { formValue, feeSchedules, deleteFeeSchedules, setFeeModalOpen } = useVendor();

  // const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const editFee = (e) => {

    const editForm = {
      fee: e.custom_fee,
      approved: e.deactivated === 'N' ? true : false ,
      turnaround_time: e.turnaround_time,
      service_id: e.service_id,
      orderTypeId: e.order_type,
      orderType: e.order_type,
      userid: e.user_id
    }
    console.log('editFee', editForm, e);
    const payload = {open:true, title:'Edit', item: { ...editForm }}

    setFeeModalOpen({ ...payload });
  }

  const tableFeeSchedules = () => {
    if (formValue) {
      return (
        <Box sx={{ width: "100%" }}>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Order Type</TableCell>
                  <TableCell>Fee</TableCell>
                  <TableCell align="center">Turn Time (Days) </TableCell>
                  <TableCell align="center">Approved</TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {feeSchedules?.map((row) => (
                  <TableRow
                    key={row.order_type}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    className="small-rows"
                  >
                    <TableCell component="th" scope="row">
                      {row.order_type}
                    </TableCell>
                    <TableCell>{row.custom_fee}</TableCell>
                    <TableCell align="center">{row.turnaround_time}</TableCell>
                    <TableCell align="center">
                      <Checkbox checked={row.deactivated === 'N' ? true : false} />
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        aria-label="delete"
                        color="error"
                        size="large"
                        sx={{ float: "right" }}
                        onClick={() => deleteFeeSchedules(formValue.userid, row.service_id)}
                      >
                        <DeleteIcon />
                      </IconButton>

                      <IconButton
                        aria-label="edit"
                        color="primary"
                        size="large"
                        sx={{ float: "right" }}
                        onClick={() => editFee(row)}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      );
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {tableFeeSchedules()}
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
       <FeeDialogs />
      </Grid>
    </Grid>
  );
}
