import * as React from 'react';
import {
  Grid,
  Alert,
  AlertTitle,
  FormGroup,
  FormControlLabel,
  Switch,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel
} from "@mui/material";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { visuallyHidden } from '@mui/utils';
import DeleteIcon from "@mui/icons-material/Delete";
import PersonIcon from "@mui/icons-material/Person";
import EmployeesDialogs from "./addEmpolyeesModal";
import VendorProfile from "./vendorProfile";
import { useVendor } from "core";
import LoadingButton from "@mui/lab/LoadingButton";
import Paper from "@mui/material/Paper";
import EmployeeTable from "./employeeTable";



function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'firstname',
    numeric: false,
    disablePadding: false,
    label: 'First Name',
  },
  {
    id: 'lastname',
    numeric: false,
    disablePadding: false,
    label: 'Last Name',
  },
  {
    id: 'job_title',
    numeric: false,
    disablePadding: false,
    label: 'Title',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
  },
  {
    id: 'phone',
    numeric: false,
    disablePadding: false,
    label: 'Phone',
  },
  {
    id: '',
    numeric: false,
    disablePadding: false,
    label: '',
  },
  {
    id: '',
    numeric: false,
    disablePadding: false,
    label: '',
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function Employees() {
  const { formValue, setFormValue, deleteEmployee, setVendorProfile, getEmpolyeeProfile, employeeProfileLoading } = useVendor();

  const [employeesChecked, setEmployeesChecked] = React.useState(formValue?.employees?.length >= 1);

  // if (formValue?.employees?.length >= 1) {
  //   setEmployeesChecked(true)
  // }

  const enableEmployees = (event) => {
    setEmployeesChecked(event.target.checked);
  };

  const deleteEmployeeFun = (employeeid, index) => {
    deleteEmployee(formValue?.userid, employeeid);
    const employees = [...formValue?.employees];
    employees.splice(index, 1);
    setFormValue({
      ...formValue,
      employees: employees,
    });
  };

  const enableEmployeesAlert = () => {
    if (!employeesChecked) {
      return (
        <Alert severity="info" className="alertProfile">
          <AlertTitle>Enable Company Employees</AlertTitle>
          This vendor has never been approved for assignments. Please review
          <br />
          their qualifications, once approved please click the Approve Vendor
          button.
          <FormGroup className="employ_switch">
            <FormControlLabel
              control={
                <Switch
                  color="success"
                  checked={employeesChecked}
                  onChange={enableEmployees}
                />
              }
              label=""
              labelPlacement="start"
            />
          </FormGroup>
        </Alert>
      );
    } else {
      return (
        <FormGroup className="employ_switch">
          <FormControlLabel
            control={
              <Switch
                color="success"
                checked={employeesChecked}
                onChange={enableEmployees}
              />
            }
            label="Employees Enabled"
            labelPlacement="end"
          />
        </FormGroup>
      );
    }
  };



  const tableEmployees = () => {

    if(employeesChecked) {
    return(
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Email </TableCell>
              <TableCell>Phone</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {formValue?.employees.map((row, index) => {

              const handleClick = () => {
                window.open( '/r/admin/vendors?id='+row.userid, '_blank', 'noopener,noreferrer')
              }

              return (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.firstname}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.lastname}
                  </TableCell>
                  <TableCell>{row.job_title}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.phone}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      sx={{ float: "right" }}
                      className="personIcon"
                      startIcon={<PersonIcon />}
                      onClick={handleClick}
                    >
                    </Button>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="delete"
                      color="error"
                      size="large"
                      sx={{ float: "right" }}
                      onClick={() => {
                        const employees = [...formValue?.employees];
                        employees.splice(index, 1);
                        setFormValue({
                          ...formValue,
                          employees: employees,
                        });
                        deleteEmployee(formValue?.userid, row.userid);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
          })}
          </TableBody>
        </Table>
      </TableContainer>
      );
    }
  };

  const addEmployee = () => {
    if (employeesChecked) {
      return <EmployeesDialogs />;
    }
  };

  return (
     <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {enableEmployeesAlert()}
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {tableEmployees()}
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {addEmployee()}
      </Grid>
    </Grid>
  );
}
