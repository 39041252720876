import React from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  FormGroup,
  FormControlLabel,
  Switch,
  Alert,
  AlertTitle,
  Link,
  Avatar,
  Autocomplete,
  styled,
} from "@mui/material";
import { useMemo, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import InfoIcon from '@mui/icons-material/Info';
import LoadingButton from "@mui/lab/LoadingButton";
import DoneAllRoundedIcon from "@mui/icons-material/DoneAllRounded";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import ModeEditOutlineRoundedIcon from "@mui/icons-material/ModeEditOutlineRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { IMaskInput } from "react-imask";
import PropTypes from "prop-types";
import avatarImg from "../../../assets/images/1.png";
import { useNavigate } from 'react-router-dom';

import signature from "../../../assets/images/signature.png";
import lawFirm from "../../../assets/images/law_firm.png";
import UploadImage from "./uploadImage";
import debouce from "lodash.debounce";
import VendorFee from "./vendorFee";


import { useVendor } from "core";

const ZipMaskCustom = React.forwardRef(function ZipMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="#0000"
      definitions={{
        "#": /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

ZipMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      definitions={{
        "#": /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const NotNumberMaskCustom = React.forwardRef(function NotNumberMaskCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="#############################"
      definitions={{
        "#": /[A-z.' -]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

NotNumberMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const Input = styled("input")({
  display: "none",
});

export default function ProfileTab() {
  const {
    states,
    vendorType,
    vendorDetail,
    assignVendorLoading,
    formValue,
    setFormValue,
    enableVendor,
    vendorApprove,
    deleteVendorProfilePicture,
    saveUpdateVendorProfilePicture,
    deleteVendorFirmLogo,
    saveUpdateVendorFirmLogo,
    saveUpdateVendorFirmSignature,
    updateVendorField,
    deleteVendorFirmSignature
  } = useVendor();

  const navigate = useNavigate();
  
  const [typeVendor, setVendorType] = React.useState("");

  const [stateValue, setStateValue] = React.useState("");

  const [timeZone, setTimeZone] = React.useState("");

  const handleSwitchChange = (event) => {
    const { name, value, checked } = event.target;
    setFormValue({
      ...formValue,
      [name]:  checked ? 'Y': 'N'
    });
  };

  const updateSwitchField = (event) => {
    console.log("event", event);
    updateVendorField({"vendorid": formValue?.userid || vendorDetail.userid, "fieldname": event.target.name, "fieldvalue": event.target.checked ? 'Y': 'N' });
  };


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValue({
      ...formValue,
      [name]: value,
    });
  };

  const updateField = (event) => {
    console.log("event", event);
    updateVendorField({"vendorid": formValue?.userid || vendorDetail.userid, "fieldname": event.target.name, "fieldvalue": event.target.value });
  };

  const updateVendorTypeField = (event) => {

    let vendor_typeid = vendor_types[event.target.value]
    updateVendorField({"vendorid": formValue?.userid || vendorDetail.userid, "fieldname": 'vendor_types', "fieldvalue": vendor_typeid });
  }

  const debouncedResults = useMemo(() => {
    return debouce(updateField, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });


  React.useEffect(() => {
    if (Object.keys(vendorDetail).length > 0) {
      setFormValue(Object.assign({}, formValue, vendorDetail));
    }
  }, [vendorDetail]);

  const timezoneChange = (event) => {
    setTimeZone(event.target.value);
  };

  const stateValueChange = (event) => {
    setStateValue(event.target.value);
    const { name, value } = event.target;
    setFormValue({
      ...formValue,
      [name]: value,
    });
  };

  const vendorTypeChange = (event) => {
    setVendorType(event.target.value);
    const { name, value } = event.target;
    setFormValue({
      ...formValue,
      [name]: value,
    });

  };

  const segments = [
    { segmentid: 68, descrip: "No Desktops" },
    { segmentid: 98, descrip: "W9 Exception" },
    { segmentid: 124, descrip: "FHA approved" },
    { segmentid: 46, descrip: "Removed - Voluntary" },
    { segmentid: 131, descrip: "Eval Analyst Lead" },
    { segmentid: 132, descrip: "CubiCasa Trained" },
    { segmentid: 133, descrip: "Property Data Collector" },
  ];

  const vendor_types = {
    'Broker': 1,
    'Agent': 2,
    'Property Inspector': 3,
    'Appraiser': 4,
    'Attorney': 5,
    'Notary': 6
  }

  const normalizeValue = (value) => {
    return value || "";
  };

  const ApproveVendorAlert = () => {
    if (formValue.exclude === "Y" && vendorApprove?.status !== "success") {
      return (
        <Alert severity="info" className="alertProfile">
          <AlertTitle>Approve Vendor For Work</AlertTitle>
          <p className="alertP">
            This vendor has never been approved for assignments. Please review
            their qualifications, once approved please click the Approve Vendor
            button.
          </p>

          <LoadingButton
            startIcon={<DoneAllRoundedIcon />}
            loading={assignVendorLoading}
            loadingPosition="start"
            variant="contained"
            type="submit"
            size="large"
            className="alertBtn"
            onClick={() => enableVendor(formValue.userid, "N")}
          >
            APPROVE VENDOR
          </LoadingButton>
        </Alert>
      );
    }
  };

  const changeHandler = (event) => {
    let files = event.target.files[0];
    if (files) {
      saveUpdateVendorProfilePicture(formValue?.userid, files);
    }
  };

  const uploadFirmLogo = (event) => {
    console.log("uploadFirmLogo");
    let files = event.target.files[0];
    if (files) {
      saveUpdateVendorFirmLogo(formValue?.userid, files);
    }
  };

  const uploadFirmSignature = (event) => {
    console.log("uploadFirmSignature");
    let files = event.target.files[0];
    if (files) {
      saveUpdateVendorFirmSignature(formValue?.userid, files);
    }
  };

  const uploadUserImage = () => {
    if (formValue?.userid) {
      if (formValue?.profile_picture == null) {
        return (
          <label>
            <Input type="file" name="file" onChange={changeHandler} />
            <Button
              variant="contained"
              component="span"
              className="uploadBtn"
              startIcon={<FileUploadOutlinedIcon />}
            ></Button>
          </label>
        );
      } else {
        return (
          <label>
            <Input type="file" name="file" onChange={changeHandler} />
            <Button
              variant="contained"
              component="span"
              className="uploadBtn"
              startIcon={<ModeEditOutlineRoundedIcon />}
            ></Button>
          </label>
        );
      }
    }
  };

  const removeUserImage = () => {
    if (formValue?.profile_picture) {
      return (
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            deleteVendorProfilePicture(formValue?.userid);
          }}
          className="removeImage"
          startIcon={<CloseRoundedIcon />}
        ></Button>
      );
    }
  };

  const gotoCompanyInfo = () => {
    let searchParam = new URLSearchParams({'id': formValue.parent_user});
    navigate('?' + searchParam.toString());
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {ApproveVendorAlert()}

        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <Typography variant="h5" component="h5">
              Contact Information
            </Typography>

            <Grid container spacing={1} rowSpacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  id={'txt-input-firtname'}
                  label="FIRST NAME"
                  name="firstname"
                  value={normalizeValue(formValue?.firstname)}
                  fullWidth
                  variant="outlined"
                  onChange={(event)=>handleInputChange(event)}
                  onBlur={(event)=>updateField(event)}
                  //onMouseLeave={debouncedResults}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  id={'txt-input-lastname'}
                  label="LAST NAME"
                  name="lastname"
                  value={normalizeValue(formValue?.lastname)}
                  fullWidth
                  variant="outlined"
                  onChange={(event)=>handleInputChange(event)}
                  onBlur={(event)=>updateField(event)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  id={'txt-input-job-title'}
                  label="JOB TITLE"
                  name="job_title"
                  value={normalizeValue(formValue?.job_title)}
                  fullWidth
                  variant="outlined"
                  onChange={(event)=>handleInputChange(event)}
                  onBlur={(event)=>updateField(event)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Grid container spacing={1} rowSpacing={2}>
                  <Grid item xs={8} sm={8} md={8}>
                    <TextField
                      id={'txt-input-phone'}
                      label="PHONE"
                      name="phone"
                      fullWidth
                      variant="outlined"
                      value={normalizeValue(formValue?.phone)}
                      onChange={(event)=>handleInputChange(event)}
                      onBlur={(event)=>updateField(event)}
                      InputProps={{
                        inputComponent: TextMaskCustom,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={4}>
                    <TextField
                      id={'txt-input-phone-ext'}
                      label="EXT"
                      name="phoneext"
                      value={normalizeValue(formValue?.phoneext)}
                      fullWidth
                      variant="outlined"
                      type="number"
                      onChange={(event)=>handleInputChange(event)}
                      onBlur={(event)=>updateField(event)}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                 id={'txt-input-mobile'}
                  label="MOBILE"
                  name="mobile"
                  fullWidth
                  variant="outlined"
                  value={normalizeValue(formValue?.mobile)}
                  onChange={(event)=>handleInputChange(event)}
                  onBlur={(event)=>updateField(event)}
                  InputProps={{
                    inputComponent: TextMaskCustom,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                 id={'txt-input-email'}
                  label="EMAIL"
                  name="email"
                  value={ formValue?.email && formValue?.email.includes('new_vendor_') ? '' : normalizeValue(formValue?.email)}
                  type="email"
                  fullWidth
                  variant="outlined"
                  onChange={(event)=>handleInputChange(event)}
                  onBlur={(event)=>updateField(event)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormGroup row>
                  <FormControlLabel
                      control={<Switch
                      checked={formValue?.email_opt_in === 'Y'}
                      color="success" />}
                    label="EMAIL OPT-IN"
                    name="email_opt_in"
                    labelPlacement="top"
                    onChange={(event)=>handleSwitchChange(event)}
                    onClick={(event)=>updateSwitchField(event)}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormGroup row>
                  <FormControlLabel
                      control={<Switch
                      checked={formValue?.text_opt_in === 'Y'}
                      color="success" />}
                    name="text_opt_in"
                    label="SMS OPT-IN"
                    labelPlacement="top"
                    onChange={(event)=>handleSwitchChange(event)}
                    onClick={(event)=>updateSwitchField(event)}
                  />
                </FormGroup>
              </Grid>
            </Grid>
            <Typography variant="h5" component="h5">
              Company Information
            </Typography>

            {formValue.parent_user > 0 &&
              <Alert severity="info" className="alertProfile">
                <AlertTitle>Law Firm Information</AlertTitle>
                <p className="alertC">
                  This user is a employee of {formValue.parent_company_name} <br/>
                  <Link onClick={gotoCompanyInfo} sx={{cursor: 'pointer', marginRight: '10px'}}>
                    Please click
                  </Link>
                  here to visit the law firm main profile page
                </p>
                <Button
                  variant="contained"
                  sx={{position: 'absolute', top: '18px', right: '18px'}}
                  onClick={gotoCompanyInfo}
                >
                  Firm Profile
                </Button>
              </Alert>
            }
            <Grid container spacing={1} rowSpacing={2}>
              <Grid item xs={12}>
                <TextField
                 id={'txt-input-company-name'}
                  label="COMPANY NAME"
                  name="company_name"
                  value={normalizeValue(formValue?.company_name || formValue?.parent_company_name)}
                  fullWidth
                  variant="outlined"
                  onChange={(event)=>handleInputChange(event)}
                  onBlur={(event)=>updateField(event)}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                 id={'txt-input-company-address'}
                  label="ADDRESS"
                  name="company_address1"
                  value={normalizeValue(formValue?.company_address1 || formValue?.parent_company_address1)}
                  fullWidth
                  variant="outlined"
                  onChange={(event)=>handleInputChange(event)}
                  onBlur={(event)=>updateField(event)}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                 id={'txt-input-suite'}
                  label="APT/SUITE"
                  name="company_address2"
                  value={normalizeValue(formValue?.company_address2 || formValue?.parent_company_address2)}
                  fullWidth
                  variant="outlined"
                  onChange={(event)=>handleInputChange(event)}
                  onBlur={(event)=>updateField(event)}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1} rowSpacing={2}>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                     id={'txt-input-city'}
                      label="CITY"
                      name="company_city"
                      value={normalizeValue(formValue?.company_city || formValue?.parent_company_city)}
                      fullWidth
                      variant="outlined"
                      onChange={(event)=>handleInputChange(event)}
                      onBlur={(event)=>updateField(event)}
                      required
                      InputProps={{
                        inputComponent: NotNumberMaskCustom,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={3}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        STATE
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="company_state"
                        value={
                          stateValue ||
                          normalizeValue(
                            formValue?.company_state?.toUpperCase?.() || formValue?.parent_company_state?.toUpperCase?.()
                          )
                        }
                        required
                        label="STATE"
                        onChange={(event)=>handleInputChange(event)}
                        onBlur={(event)=>updateField(event)}
                      >
                        {states &&
                          states.map((state) => (
                            <MenuItem key={state} value={state}>
                              {state}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={6} md={3}>
                    <TextField
                     id={'txt-input-zipcode'}
                      label="ZIP"
                      name="company_zipcode"
                      value={normalizeValue(formValue?.company_zipcode || formValue?.parent_company_zipcode)}
                      fullWidth
                      variant="outlined"
                      onChange={(event)=>handleInputChange(event)}
                      onBlur={(event)=>updateField(event)}
                      required
                      InputProps={{
                        inputComponent: ZipMaskCustom,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    TIMEZONE
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={normalizeValue(formValue?.company_timezone || formValue?.parent_company_timezone)}
                    name={'company_timezone'}
                    label="TIMEZONE"
                    onChange={(event)=>handleInputChange(event)}
                    onBlur={(event)=>updateField(event)}
                  >
                    <MenuItem value="Pacific">Pacific Time</MenuItem>
                    <MenuItem value="Mountain">Mountain Time</MenuItem>
                    <MenuItem value="Central">Central Time</MenuItem>
                    <MenuItem value="Eastern">Eastern Time</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  id={'txt-input-accounting-id'}
                  label="ACCOUNTING ID (INTERNAL USE)"
                  name='accounting_reference_number'
                  value={normalizeValue(formValue?.accounting_reference_number)}
                  onChange={(event)=>handleInputChange(event)}
                  onBlur={(event)=>updateField(event)}

                  fullWidth
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <UploadImage
                  title={"FIRM LOGO"}
                  userid={normalizeValue(formValue?.userid)}
                  imageUrl={normalizeValue(formValue?.firm_logo || formValue?.parent_firm_logo)}
                  btnTitle={"Upload Firm Logo"}
                  btnid={"firm-Logo"}
                  remove={deleteVendorFirmLogo}
                  upload={uploadFirmLogo}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    TIMEZONE
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={normalizeValue(formValue?.company_timezone)}
                    name={'company_timezone'}
                    label="TIMEZONE"
                    onChange={(event)=>handleInputChange(event)}
                    onBlur={(event)=>updateField(event)}
                  >
                    <MenuItem value="Pacific">Pacific Time</MenuItem>
                    <MenuItem value="Mountain">Mountain Time</MenuItem>
                    <MenuItem value="Central">Central Time</MenuItem>
                    <MenuItem value="Eastern">Eastern Time</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  id={'txt-input-accounting-id'}
                  label="ACCOUNTING ID (INTERNAL USE)"
                  name='accounting_reference_number'
                  value={normalizeValue(formValue?.accounting_reference_number)}
                  onChange={(event)=>handleInputChange(event)}
                  onBlur={(event)=>updateField(event)}

                  fullWidth
                  variant="outlined"
                />
              </Grid>

              {/* <Grid item xs={12} sm={12} md={6}>
                <UploadImage
                  title={"FIRM SIGNATURE"}
                  userid={normalizeValue(formValue?.userid)}
                  imageUrl={normalizeValue(formValue?.firm_signature)}
                  btnTitle={"Upload Firm Signature"}
                  btnid={"firm-signature"}
                  remove={deleteVendorFirmSignature}
                  upload={uploadFirmSignature}
                />
              </Grid> 

               <Grid item xs={12} sm={12} md={6}>
                <UploadImage
                  title={"FIRM SIGNATURE"}
                  userid={normalizeValue(formValue?.userid)}
                  imageUrl={normalizeValue(formValue?.firm_signature)}
                  btnTitle={"Upload Firm Signature"}
                  btnid={"firm-signature"}
                  remove={deleteVendorFirmSignature}
                  upload={uploadFirmSignature}
                />
              </Grid>*/}
            </Grid>

            {/* <Typography variant="h5" component="h5">
              Vendor Performance
            </Typography>

            <Grid container spacing={1} rowSpacing={2}>
              <Grid item xs={12} sm={4} md={4}>
                <Typography variant="h6" component="h6">
                  AVERAGE QC RATING <br />
                  (IF APPLICABLE) <br />
                  <Rating
                    name="read-only"
                    defaultValue={4}
                    size="large"
                    readOnly
                  />
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <Typography variant="h6" component="h6">
                  % TURN TIME <br />
                  SUCCESS RATE <br />
                  <span className="boldSpan">95%</span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <Typography variant="h6" component="h6">
                  % ORDER <br />
                  REJECTION RATE <br />
                  <span className="boldSpan">5%</span>
                </Typography>
              </Grid>
            </Grid> */}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <Grid container spacing={1} rowSpacing={2}>
              <Grid
                item
                xs={12}
                style={{ textAlign: "center", position: "relative" }}
              >
                <Avatar
                  alt="Avatar"
                  src={
                    formValue?.profile_picture == null
                      ? avatarImg
                      : `data:image/jpeg;base64,${formValue?.profile_picture}`
                  }
                  sx={{
                    width: 200,
                    height: 200,
                    display: "inline-flex",
                    marginTop: 3,
                  }}
                />

                {uploadUserImage()}
                {removeUserImage()}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" component="h5">
                  Vendor Details
                </Typography>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    VENDOR TYPE
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="vendor_type"
                    value={typeVendor || normalizeValue(formValue?.vendor_type)}
                    label="VENDOR TYPE"
                    onChange={(event)=>vendorTypeChange(event)}
                    onBlur={(event)=>updateVendorTypeField(event)}
                    required
                  >
                    {vendorType &&
                      vendorType.map((type) => (
                        <MenuItem key={type.id} value={type.description}>
                          {type.description}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                 id={'txt-input-firtname'}
                  label="ORDER CAPACITY"
                  name="vendorcapacity"
                  value={normalizeValue(formValue?.vendorcapacity)}
                  fullWidth
                  variant="outlined"
                  onChange={(event)=>handleInputChange(event)}
                  onBlur={(event)=>updateField(event)}
                  type="number"
                  required
                />

                <Typography variant="h5" component="h5">
                  Coverage
                </Typography>

                <img
                  src={`https://i0.wp.com/mark-metals.com/wp-content/uploads/2020/01/map-mark-metals-650x516.png`}
                  srcSet={`https://i0.wp.com/mark-metals.com/wp-content/uploads/2020/01/map-mark-metals-650x516.png`}
                  alt="title"
                  loading="lazy"
                  style={{ width: "100%" }}
                />
              </Grid>

            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" component="h5">
            Vendor Fee
          </Typography>
          <VendorFee />
        </Grid>
      </Grid>
    </Grid>
  );
}
