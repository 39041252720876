import { useState } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import InputMask from "react-input-mask";
import { normalizeValue } from 'utils'
import { useVendor } from "core";

export default function Experience () {
  const { formValue, setFormValue, updateVendorField } = useVendor();
  const [updatedFields, setUpdatedFields] = useState({}); 

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValue({
      ...formValue,
      [name]: value,
    });

    // To know what fields have been updated
    setUpdatedFields({
      ...updatedFields,
      [name]: value
    })
  };

  const onChangeRadio = (e, name) => {
    const { value } = e.target
    setFormValue({
      ...formValue,
      [name]: value,
    });

    // To know what fields have been updated
    setUpdatedFields({
      ...updatedFields,
      [name]: value
    })
  };

  const updateField = (event) => {
    const { name, value } = event.target;
    if(updatedFields[name]){
      updateVendorField({"vendorid": formValue?.userid || vendorDetail.userid, "fieldname": name, "fieldvalue": value });
      delete updatedFields[name]
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={9} sx={{mt: 2}}>
        Years of Experience Practicing in Real Estate or Related Field of Law:
      </Grid>
      <Grid item xs={3}>
        <InputMask
          mask="999"
          value={normalizeValue(formValue?.experience_years)}
          maskChar=""
          onChange={handleInputChange}
          onBlur={updateField}
          >
          {() => <TextField 
                      id="experience_years" 
                      name="experience_years" 
                  />
          }
        </InputMask>
      </Grid>
      <Grid item xs={9}>
        Are you aware of any fact, circumstance, incident, or situation that may result in a malpractice claim being made against you?
      </Grid>
      <Grid item xs={3}>
        <RadioGroup row>
          <FormControlLabel 
            value="yes" 
            control={<Radio id="malpractice_claim" name="malpractice_claim"/>}
            label="Yes" 
            checked={formValue?.malpractice_claim == "yes"}
            onChange={(e) => onChangeRadio(e, 'malpractice_claim')}
            onBlur={updateField}
          />
          <FormControlLabel 
            value="no"
            control={<Radio id="malpractice_claim" name="malpractice_claim"/>}
            label="No" 
            checked={formValue?.malpractice_claim == "no"}
            onChange={(e) => onChangeRadio(e, 'malpractice_claim')}
            onBlur={updateField}
            sx={{marginLeft: '16px'}}
          />
        </RadioGroup>
      </Grid>
      {formValue?.malpractice_claim == "yes" &&
        <Grid item xs={12}>
          <TextField
            id="malpractice_claim_explain" 
            name="malpractice_claim_explain" 
            fullWidth
            multiline
            rows={4}
            label="Please explain"
            variant="outlined"
            value={normalizeValue(formValue?.malpractice_claim_explain)}
            onChange={handleInputChange}
            onBlur={updateField}
            />
        </Grid>
      }
      <Grid item xs={9}>
        Have you ever been the subject of a reprimand, disciplinary action, or criminal action by any federal, state, or local authority, professional association, or state licensing board?
      </Grid>
      <Grid item xs={3}>
        <RadioGroup row>
          <FormControlLabel 
            value="yes" 
            control={<Radio id="criminal_action" name="criminal_action"/>}
            label="Yes" 
            checked={formValue?.criminal_action == "yes"}
            onChange={(e) => onChangeRadio(e, 'criminal_action')}
            onBlur={updateField}
          />
          <FormControlLabel 
            value="no" 
            control={<Radio id="criminal_action" name="criminal_action"/>}
            label="No" 
            checked={formValue?.criminal_action == "no"}
            onChange={(e) => onChangeRadio(e, 'criminal_action')}
            sx={{marginLeft: '16px'}}
            onBlur={updateField}
          />
        </RadioGroup>
      </Grid>
      {formValue?.criminal_action == "yes" &&
        <Grid item xs={12}>
          <TextField
            id="criminal_action_explain" 
            name="criminal_action_explain" 
            fullWidth
            multiline
            rows={4}
            label="Please explain"
            variant="outlined"
            value={normalizeValue(formValue?.criminal_action_explain)}
            onChange={handleInputChange}
            onBlur={updateField}
            />
        </Grid>
      }
      <Grid item xs={9}>
        Are you aware of any actual, potential, or perceived conflict of interest that may exist as a result of your providing services to Voxtur?
      </Grid>
      <Grid item xs={3}>
        <RadioGroup row>
          <FormControlLabel 
            value="yes" 
            control={<Radio id="perceived_conflict" name="perceived_conflict"/>}
            label="Yes" 
            checked={formValue?.perceived_conflict == "yes"}
            onChange={(e) => onChangeRadio(e, 'perceived_conflict')}
            onBlur={updateField}
          />
          <FormControlLabel 
            value="no" 
            control={<Radio id="perceived_conflict" name="perceived_conflict"/>}
            label="No" 
            checked={formValue?.perceived_conflict == "no"}
            onChange={(e) => onChangeRadio(e, 'perceived_conflict')}
            onBlur={updateField}
            sx={{marginLeft: '16px'}}
          />
        </RadioGroup>
      </Grid>
      {formValue?.perceived_conflict == "yes" &&
        <Grid item xs={12}>
          <TextField
            id="perceived_conflict_explain" 
            name="perceived_conflict_explain" 
            fullWidth
            multiline
            rows={4}
            label="Please explain"
            variant="outlined"
            value={normalizeValue(formValue?.perceived_conflict_explain)}
            onChange={handleInputChange}
            onBlur={updateField}
            />
        </Grid>
      }
    </Grid>
  );
}