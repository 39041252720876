import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddBoxIcon from "@mui/icons-material/AddBox";
import InputMask from "react-input-mask";
import { normalizeValue } from 'utils'
import { useVendor } from "core";

export default function StateDBA () {
  const { formValue, setFormValue, updateStateDBA, deleteStateDBA, states } = useVendor();
  const [ updatedDBAs, setUpdatedDBAs] = useState(false); 

  const handleInputChange = (event, index) => {
    const { name, value } = event.target;
    const dbalist = formValue.dbalist;
    formValue.dbalist[index][name] = value;
    setFormValue({
      ...formValue,
      dbalist: dbalist,
    });
    setUpdatedDBAs(true)
  };

  const updateField = (event, index) => {
    if( updatedDBAs ) {
      updateStateDBA(formValue.dbalist[index])
      setUpdatedDBAs(false)
    } 
  };

  return (
    <FormGroup>
      <Box sx={{mt: 2, mb: 4}}>
        <Typography sx={{fontSize: '1rem', fontWeight: 600}}>
          DBA Management
        </Typography>
        <Typography sx={{fontSize: '0.875rem'}}>
          DBA Allows law firms to adapt their business contact information to state level DBA entity requirements.
        </Typography>
      </Box>
      <Grid container sx={{marginBottom: '12px', borderBottom: '1px solid black'}} color="primary" spacing={2}>
        <Grid item sm={1}>STATE</Grid>
        <Grid item sm={4}>DBA NAME</Grid>
        <Grid item sm={3}>ADDRESS</Grid>
        <Grid item sm={2}>CITY</Grid>
        <Grid item sm={1}>ZIP</Grid>
        <Grid item sm={1}></Grid>
      </Grid>
      {
        formValue?.dbalist?.map((item, index) => {
          return <Grid container key={"dba"+index} spacing={2} sx={{marginBottom: '16px'}}>
                  <Grid item sm={1}>
                    <Select
                      fullWidth
                      id="state"
                      name="state"
                      value={normalizeValue(item?.state)}
                      onChange={(e) => handleInputChange(e, index)}
                      onBlur={(e) => updateField(e, index)}
                      required
                    >
                      {states.map((state) => (
                        <MenuItem key={state} value={state}>
                          {state}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item sm={4}>
                    <TextField
                      fullWidth
                      id="dba_name" 
                      name="dba_name" 
                      variant="outlined"
                      value={normalizeValue(item?.dba_name)}
                      onChange={(e) => handleInputChange(e, index)}
                      onBlur={(e) => updateField(e, index)}
                      />
                  </Grid>
                  <Grid item sm={3}>
                    <TextField
                      fullWidth
                      id="address" 
                      name="address" 
                      variant="outlined"
                      value={normalizeValue(item?.address)}
                      onChange={(e) => handleInputChange(e, index)}
                      onBlur={(e) => updateField(e, index)}
                      />
                  </Grid>
                  <Grid item sm={2}>
                    <TextField
                      fullWidth
                      id="city" 
                      name="city" 
                      variant="outlined"
                      value={normalizeValue(item?.city)}
                      onChange={(e) => handleInputChange(e, index)}
                      onBlur={(e) => updateField(e, index)}
                      />
                  </Grid>
                  <Grid item sm={1.5}>
                    <InputMask
                      mask="99999"
                      value={normalizeValue(item?.zip_code)}
                      maskChar="_"
                      onChange={(e) => handleInputChange(e, index)}
                      onBlur={(e) => updateField(e, index)}
                      >
                      {() => <TextField 
                                fullWidth
                                id="zip_code" 
                                name="zip_code" 
                              />
                      }
                  </InputMask>
                  </Grid>
                  <Grid item sm={0.5} sx={{position: 'relative'}}>
                    <IconButton
                      aria-label="delete"
                      color="error"
                      size="large"
                      sx={{ float: "right" }}
                      onClick={() => {
                        let dba = formValue?.dbalist[index]
                        deleteStateDBA({
                          vendor_id: formValue.userid,
                          state: dba.state
                        })
                        const dbalist = [...formValue?.dbalist];
                        dbalist.splice(index, 1);
                        setFormValue({
                          ...formValue,
                          dbalist: dbalist,
                        });
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
        })
      }
      <Grid container spacing={2} rowSpacing={2}>
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <Button
            variant="text"
            endIcon={<AddBoxIcon />}
            size="large"
            color="primary"
            className="rightM"
            onClick={() => {
              updateStateDBA({
                vendor_id: formValue.userid
              }, true)
            }}
          >
              Add DBA
            </Button>
        </Grid>
      </Grid>
  </FormGroup>
  );
}