import React from "react";
import { Grid, TextField } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import { useVendor } from "core";


const rows = [
  createData(
    "Attorney Opinion Letter 1",
    "123 Any Address, US 11111",
    "1/1/2022",
    "1/1/2022",
    "Available"
  ),
  createData(
    "Attorney Opinion Letter 2",
    "123 Any Address, US 11111",
    "1/1/2022",
    "1/1/2022",
    "In Progress"
  ),
  createData(
    "Attorney Opinion Letter 3",
    "123 Any Address, US 11111",
    "1/1/2022",
    "1/1/2022",
    "Completed"
  ),
  createData(
    "Attorney Opinion Letter 4",
    "123 Any Address, US 11111",
    "1/1/2022",
    "1/1/2022",
    "Completed"
  ),
  createData(
    "Attorney Opinion Letter 5",
    "123 Any Address, US 11111",
    "1/1/2022",
    "1/1/2022",
    "Completed"
  ),
  createData(
    "Attorney Opinion Letter 6",
    "123 Any Address, US 11111",
    "1/1/2022",
    "1/1/2022",
    "Completed"
  ),
  createData(
    "Attorney Opinion Letter 7",
    "123 Any Address, US 11111",
    "1/1/2022",
    "1/1/2022",
    "Completed"
  ),
];

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

export default function OrderHistory({ data }) {
    // const { vendorOrder } = useVendor();

    // console.log('vendorOrder', vendorOrder);

  return (
    <>
      <Grid container spacing={1} rowSpacing={2} sx={{ marginBottom: 3 }}>
        <Grid item xs={12} sm={6} md={6} lg={8}></Grid>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <TextField
            fullWidth
            id="date"
            label="EXPIRATION DATE"
            type="date"
            defaultValue="2017-05-24"
          />
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Order Type</TableCell>
              <TableCell>Order Address</TableCell>
              <TableCell align="center">Date Accepted </TableCell>
              <TableCell align="center">Date Completed</TableCell>
              <TableCell align="center">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell>{row.calories}</TableCell>
                <TableCell align="center">{row.fat}</TableCell>
                <TableCell align="center">{row.carbs}</TableCell>
                <TableCell align="center">{row.protein}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
