import * as React from 'react';
import PropTypes from 'prop-types';
import {
  styled,
  Grid,
  Button,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  FormGroup,
  FormControlLabel,
  OutlinedInput,
  InputAdornment,
  Checkbox } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import AddBoxIcon from "@mui/icons-material/AddBox";
import Dialog from '@mui/material/Dialog';
import {css} from "@mui/system";
import { IMaskInput } from 'react-imask';
import { useVendor } from "core";
import SimCardDownloadRoundedIcon from "@mui/icons-material/SimCardDownloadRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LicensureItem from "./licensureList";
import { isEmail, isPhone } from 'utils'


const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      definitions={{
        '#': /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  }
}, css`
 .employees-dialog-header{
    background: ${theme.palette.primary.main};
    position: relative;
    color: #fff;
    padding: 20px 40px;
    font-weight: 400;
    font-size: 20px;
    .MuiButtonBase-root{
      color: #ffffff5e;
      top: 15px;
    }
  }

  .MuiInputLabel-formControl {
    background-color: #ffffff;
  }
  .MuiButtonBase-root{
    top: 0px;
  }
  .rightM {
    margin-right: 6px;
    color: #005fd4;
    padding: 8px 11px;
  }
`));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function EmployeesDialogs() {
  const { states, formValue, saveEmployee, setTabValue } = useVendor();
  const [open, setOpen] = React.useState(false);
  const [maxWidth, setMaxWidth] = React.useState('md');
  const [addEmployee, setAddEmployee] = React.useState({
    vendor_type: 6,
    certificates:[{}]
  })
  const [requriedFields, setRequriedFields] = React.useState(null)

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const normalizeValue = (value) => {
    return value || "";
  };

   const handleInputFiledChange = (event, index) => {
     const { name, value } = event.target;
      setAddEmployee({
        ...addEmployee,
        [name]: value,
      });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setAddEmployee({
      ...addEmployee,
      showPassword: !addEmployee.showPassword,
    });
  };

  const handlePassword = (prop) => (event) => {
    setAddEmployee({ ...addEmployee, [prop]: event.target.value });
  };

  const onLicensureUpdate = (item, index) => {
    const updatedLicensure = addEmployee.certificates;
    addEmployee.certificates[index] = item;
    setAddEmployee({
      ...addEmployee,
      certificates: updatedLicensure,
    });
  }

  const saveEmployeeFun = () => {
    var requried = {}
    if (!addEmployee.firstname) {
      requried.firstname = true
    }
    if (!addEmployee.mobile || !isPhone(addEmployee.mobile)) {
      requried.mobile = true
    }
    if (!addEmployee.title) {
      requried.title = true
    }
    if (!addEmployee.phone || !isPhone(addEmployee.phone)) {
      requried.phone = true
    }
    if (!addEmployee.email || !isEmail(addEmployee.email)) {
      requried.email = true
    }
    if (!addEmployee.password) {
      requried.email = true
    }

    var cert_required = false
    for (let certificate of addEmployee.certificates) {
      certificate.required = {}
      if (!certificate.license_number)
        certificate.required.license_number = true
      if (!certificate.last_updated)
        certificate.required.last_updated = true
      if (!certificate.license_expiration)
        certificate.required.license_expiration = true
      if (Object.keys(certificate.required).length !== 0) {
        cert_required = true
      }
    }

    if (Object.keys(requried).length !== 0 || cert_required) {
      setRequriedFields(requried)
      return
    }

    const newItem = {
      ...addEmployee,
      parent_user: formValue?.userid
    }
    setAddEmployee({
      newItem
    });
    saveEmployee(newItem);
    setTabValue(0);
    setOpen(false);
  }


  const addLicensure = () => {
    return addEmployee?.certificates?.map((item, index) => {
      return (<LicensureItem key={index} item={item} index={index} onUpdate={(item) => onLicensureUpdate(item, index)} />);
    });
  };

  return (
    <div>
      <Button
        variant="text"
        endIcon={<AddBoxIcon />}
        size="large"
        color="primary"
        className="rightM"
        onClick={handleClickOpen}
        sx={{ float: "right", color: "#005fd4", padding: "8px 11px" }}
      >
        Add Employee
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="employees-dialog-header"
        maxWidth={maxWidth}
        open={open}
      >
        <BootstrapDialogTitle className="employees-dialog-header" onClose={handleClose}>
          Add Employee
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <form onSubmit={saveEmployeeFun}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography variant="h5" component="h5" sx={{ marginBottom: '10px' }}>
                Contact Information
              </Typography>

              <Grid container spacing={2} rowSpacing={2}>

                <Grid item xs={12} sm={12} md={4}>
                  <TextField
                    label="FIRST NAME"
                    name="firstname"
                    value={normalizeValue(addEmployee?.firstname)}
                    error={requriedFields?.firstname}
                    fullWidth
                    variant="outlined"
                    onChange={handleInputFiledChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <TextField
                    label="LAST NAME"
                    name="lastname"
                    value={normalizeValue(addEmployee?.lastname)}
                    fullWidth
                    variant="outlined"
                    onChange={handleInputFiledChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>

                  <TextField
                    label="MOBILE"
                    name="mobile"
                    fullWidth
                    variant="outlined"
                    value={normalizeValue(addEmployee?.mobile)}
                    error={requriedFields?.mobile}
                    onChange={handleInputFiledChange}
                    InputProps={{
                      inputComponent: TextMaskCustom,
                    }}
                  />

                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <TextField
                    label="JOB TITLE"
                    name="title"
                    value={normalizeValue(addEmployee?.title)}
                    error={requriedFields?.title}
                    fullWidth
                    variant="outlined"
                    onChange={handleInputFiledChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Grid container spacing={1} rowSpacing={2}>
                    <Grid item xs={8} sm={8} md={8}>

                      <TextField
                        label="PHONE"
                        name="phone"
                        fullWidth
                        variant="outlined"
                        value={normalizeValue(addEmployee?.phone)}
                        error={requriedFields?.phone}
                        onChange={handleInputFiledChange}
                        InputProps={{
                          inputComponent: TextMaskCustom,
                        }}
                      />

                    </Grid>
                    <Grid item xs={4} sm={4} md={4}>
                      <TextField
                        label="EXT"
                        name="phoneext"
                        value={normalizeValue(addEmployee?.phoneext)}
                        fullWidth
                        variant="outlined"
                        type="number"
                        onChange={handleInputFiledChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={4}>
                  <TextField
                    label="EMAIL"
                    name="email"
                    value={normalizeValue(addEmployee?.email)}
                    error={requriedFields?.email}
                    type="email"
                    fullWidth
                    variant="outlined"
                    onChange={handleInputFiledChange}
                    required
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Typography variant="h5" component="h5" sx={{ marginBottom: '10px', marginTop: '20px' }}>
              Licensure
            </Typography>

          {addLicensure()}

          <Grid container spacing={3} rowSpacing={2}>
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button
                variant="text"
                endIcon={<AddBoxIcon />}
                size="large"
                color="primary"
                className="rightM"
                onClick={() => {
                  setAddEmployee({
                    ...addEmployee,
                    certificates: [...addEmployee?.certificates, {}],
                  });
                }}
              >
                Add LICENSE
              </Button>
            </Grid>
          </Grid>

      <Typography variant="h5" component="h5" sx={{ marginBottom: '10px' }}>
        Security
      </Typography>

      <Grid container spacing={3} rowSpacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              PASSWORD
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={addEmployee.showPassword ? "text" : "password"}
              value={addEmployee.password}
              error={requriedFields?.password}
              onChange={handlePassword("password")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {addEmployee.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="PASSWORD"
            />
          </FormControl>

          <FormGroup>
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="FORCE NEW PASSWORD ON NEXT LOGIN"
            />
          </FormGroup>
        </Grid>
        </Grid>

          <Button variant="contained" size="large" autoFocus onClick={() => saveEmployeeFun()} style={{ margin: "10px 0" }}>
            Save changes
          </Button>
          </form>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
