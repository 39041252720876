import React from "react";
import {
  Grid,
  Alert,
  AlertTitle,
  FormGroup,
  FormControlLabel,
  Switch,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel
} from "@mui/material";
import { formatPhoneNumber } from "react-phone-number-input";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonIcon from "@mui/icons-material/Person";
import LoadingButton from "@mui/lab/LoadingButton";
import { useVendor } from "core";



export default function EmployeeTable({ item, index }) {
    const { formValue, setFormValue, deleteEmployee, getEmpolyeeProfile } = useVendor();
    const [loading, setLoading] = React.useState(false);


    const fetchEmpolyeeProfile = async () => {
        try {
          setLoading(true);
          await getEmpolyeeProfile(item.userid);
        } catch (e) {
          // handle error here.
        } finally {
          setLoading(false);
        }
    }


    return (
        <TableRow
          hover
          tabIndex={-1}
          key={index}
        >
          <TableCell
            scope="row">
            {item.firstname}
          </TableCell>
          <TableCell align="left">{item.lastname}</TableCell>
          <TableCell align="left">{item.job_title}</TableCell>
          <TableCell align="left">{item.email}</TableCell>
          <TableCell align="left">{formatPhoneNumber("+1" + item.phone)}</TableCell>
          <TableCell>
            <LoadingButton
              startIcon={<PersonIcon />}
              loading={loading}
              loadingPosition="start"
              variant="contained"
              sx={{ float: "right" }}
              className="personIcon"
              onClick={fetchEmpolyeeProfile}
            >
            </LoadingButton>
          </TableCell>
          <TableCell>
            <IconButton
              aria-label="delete"
              color="error"
              size="large"
              sx={{ float: "right" }}
              onClick={() => {
                const employees = [...formValue?.employees];
                employees.splice(index, 1);
                setFormValue({
                  ...formValue,
                  employees: employees,
                });
                deleteEmployee(formValue?.userid, item.userid);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </TableCell>
        </TableRow>
        );
}