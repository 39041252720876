import React from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Card,
  CardContent,
  Alert,
  AlertTitle,
  Box,
  LinearProgress
} from "@mui/material";

const LinearIndeterminate = () => {
  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress />
    </Box>
  )
}
import LoadingButton from '@mui/lab/LoadingButton';
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import { useSearchParams } from 'react-router-dom';
import { useVendor } from "core";
import { useMemo, useEffect } from "react";
import debouce from "lodash.debounce";

export default function SidePanel() {
  const [type, setType] = React.useState();
  const [state, setState] = React.useState();
  const [ searchParams, setSearchParams ] = useSearchParams();
  const [ searchChange, setSearchChange] = React.useState();
  const [ activeVendorId , setActiveVendorId ] = React.useState(null);
  const [selectedVendorId, setSelectedVendorId] = React.useState(null);

  const {
    getStateVendors,
    availabeVendors,
    states,
    vendorType,
    getVendorDetail,
    addVendorBtnLoading,
    stateVendor,
    vendorSearchResults,
    searchStateVendors,
    searchVenderByKey,
    setAvailableVendors,
    setFormValue,
    formValue,
    fetchStateDBA,
    addVendor,
    getVendorsBy,
    vendorLoading
  } = useVendor();

  const userid = searchParams.get("id")
  React.useEffect(async () => {
    if (userid) {
      getVendorDetail(userid);
    }
  }, [userid]);

  const stateChange = (event) => {
    setState(event.target.value);
    getVendorsBy( searchChange, event.target.value, type)

  };

  const vendorTypeChange = (event) => {
    setType(event.target.value);
  };

  const onSearchChange = (event) => {
    setSearchChange(event.target.value)
    if(event.target.value || state) {
      getVendorsBy(event.target.value, state, type)
    } else {
      setAvailableVendors([]);
    }
  }


  const onCardHovered = (vendorid) => {
    setActiveVendorId(vendorid)
  }
  const onCardLeft = (vendorid) => {
    setActiveVendorId(null)
  }

  // const debouncedResults = useMemo(() => {
  //   return debouce(onSearchChange, 300);
  // }, []);

  // useEffect(() => {
  //   return () => {
  //     debouncedResults.cancel();
  //   };
  // });

  const renderVendors = () => {
    const data = availabeVendors;
    return data && data.map((item, index) => {
      return (
        <Card
          onClick={() => {
          setSelectedVendorId(item.userid);
            getVendorDetail(item.userid);
          }}
          onMouseEnter={() => {
            onCardHovered(item.userid)

          }}
          onMouseLeave={() => {
            onCardLeft(item.userid)
          }}
          // elevation={ item.userid == activeVendorId ? 10 : 1 }
          key={index}
          className={
              "sideCardList " +
              (item?.exclude === "N" ? "exclude " : " ") +
              (item?.userid === selectedVendorId ? "selected" : "")
            }

        >
          <CardContent>
            <Typography className="cardTtitleNumber">
              {item.firstname} {item.lastname}
              <span>{(item.phone && item.phone.match(/[0-9]{10}/) ? item.phone.replace(/([0-9]{3})([0-9]{3})([0-9]{4})/,"($1) $2-$3") : item.phone)}</span>
            </Typography>
            <Typography className="cardBold">{item.parent_company_name || item.company_name}</Typography>
          </CardContent>
        </Card>
      );
    });
  };

  const addNewVendorBtn = () => {
      return(
      <LoadingButton
          onClick={
            () => {
              const uniqueid = (new Date()).getTime()
              addVendor({email: `new_vendor_${uniqueid}@new.com`, "vendor_type": 6})
            }
          }
          startIcon={<AddBoxRoundedIcon />}
          style={{ float: "right" }}
          loading={addVendorBtnLoading}
          loadingPosition="start"
          variant="contained"
          type="submit"
        >
          NEW VENDOR
        </LoadingButton>
      );
  };

  const renderEmptyContainer = () => {
    if (availabeVendors.length === 0) {
      return (
        <Alert severity="info" className="alertProfile" style={{marginBottom: 8}}>
          <AlertTitle>Select State</AlertTitle>
          you have to select state for getting vendors.
        </Alert>
      );
    }
    return null;
  };

  const noVendorFound = () => {
    if (stateVendor === 1) {
      return (
        <Alert severity="error" className="alertProfile">
          No Vendors Found
        </Alert>
      );
    }
    return null;
  };

  return (
    <>
      <Grid item xs={12} sx={{ marginTop: 2 }} className="padding_for_top">
        <TextField
          id="standard-basic"
          label="SEARCH VENDORS"
          size="small"
          variant="outlined"
          onChange={debouce(onSearchChange, 300)}
        />

        <FormControl size="small" sx={{ marginLeft: 1, minWidth: 140 }}>
          <InputLabel id="demo-simple-select-label">STATES</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={state || ""}
            label="STATES"
            onChange={stateChange}
          >
            {states && states.map((state) => (
              <MenuItem key={state} value={state}>
                {state}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

         {addNewVendorBtn()}

      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={3} className="height_for_sidbar">
        {renderEmptyContainer()}
        {noVendorFound()}
        { availabeVendors && availabeVendors.length > 0 &&
          <Grid item xs={12} justifyContent={'flex-start'} style={{minHeight: 22}}>
            <Typography style={{fontSize: 12}}>Found ({availabeVendors.length}) vendors</Typography>
             { vendorLoading && <LinearIndeterminate /> }
          </Grid>
        }
        {renderVendors()}
      </Grid>
    </>
  );
}
